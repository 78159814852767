<template>
    <div class="text-remaining">
        <div v-if="remainingDays" class="text-center" v-bind:class=[textState]>
            {{ messageRemaining }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SubscriptionRemainingDays",
    props: {
        tracker: null,
    },

    computed: {
        //Chek if a tracker is selected
        isTrackerSelected: function () {
            return this.tracker !== null;
        },

        //Check if the subscription is ended
        isSubscriptionEnded: function () {
            return this.isTrackerSelected && this.tracker.tracker_status.sub_end_date && (new Date(this.tracker.tracker_status.sub_end_date) < new Date());
        },

        //Compute how many days left
        remainingDays: function () {
            var DAYINMILLISECOND = 1000 * 3600 * 24;
            
            if (this.isTrackerSelected && this.tracker.tracker_status.sub_end_date) {
                var subDaysLeft = (new Date(this.tracker.tracker_status.sub_end_date).getTime() - new Date().getTime() ) / DAYINMILLISECOND
                return Math.round(subDaysLeft)
            }
            else {
                return 0
            }
        },

        //Return the right message to display
        messageRemaining: function () {
            if (this.remainingDays < 30){
                return (this.isSubscriptionEnded ? app.$t('subscription_ended') : app.$t('subscription_timeleft') + this.remainingDays + " " + app.$('days')) + ", il est temps de le renouveler"
            }
            else {
                return app.$t('subscription_timeleft') + " " + this.remainingDays + " " + app.$t('days')
            }
        },

        textState: function () {
            return this.remainingDays < 30 ? "text-danger" : "text-success";
        }
    },
}
</script>

<style lang="scss" scoped>
.text-remaining{
    max-width: 100vw;
    height: 50px;
    margin: 10px;
}
</style>