<template>
    <b-button class="b-btn-checkout" @click="checkout" block variant="primary" :disabled="!getSelectedSubscription">
        <div v-if="getSelectedSubscription != null">
          {{$t('subscribe_for')}} {{ getSelectedSubscription.meta_data.months | formatSubscriptionDuration }}
        </div>
        <div v-else>
          {{$t('select_a_subscription')}}
        </div>
    </b-button>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: 'SubscriptionCheckOutButton',

    computed: {
      ...mapGetters('subscriptions', ['getSelectedSubscription']),
      ...mapGetters('devices', ['getSelectedDevice']),
      ...mapGetters('users', ['isWebView']),
      stripeReturnPath() {
        return ('/subscription' + '?serial=' + this.getSelectedDevice.serial + (this.isWebView ? '&webview=true' : ''));
      }
    },

    methods : {
      ...mapActions('users', ['paiementSession']),
        checkout: function () {
          let self = this;
          let Order = [];

          Order.push(this.getSelectedDevice)
          Order[0].price = this.getSelectedSubscription;

          let sub_devices = Order.filter(dev => dev.price != undefined &&
              dev.price.meta_data.months != undefined && dev.price.meta_data.months >0);

          let post_data = sub_devices.map(a => {
            return {
              serial: a.serial, months: a.price.meta_data.months, price: a.price.id
            }
          });
          this.paiementSession({ subscriptions: post_data, return_path: this.stripeReturnPath })
        },
    }
}
</script>

<style scoped>

.b-btn-checkout {
  border-width: 1.5px;
  background: #0091F8;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.875rem 2rem;
}

</style>