<template>
    <b-button v-on:click="setSelectedSubscription(trackersubscription)" class="btn-price" block variant="outline-secondary my-3" v-bind:class="{'btn-price-active': (getSelectedSubscription && getSelectedSubscription.id==trackersubscription.id)}">
        <div class="row align-items-center">
            <div class="col-8 col-md-10 text-left text-dark">
                <div class="d-block font-weight-bold">
                    {{ trackersubscription.meta_data.months | formatSubscriptionDuration }} {{$t('subscription')}}
                </div>
                <div class="d-block font-weight-normal">
                    {{ trackersubscription | formatSubscriptionTotalPrice }}
                </div>
            </div>
            <div class="col-4 col-md-2 text-right">
                <div class="d-block rebate-area">
                    <RebateBadge :rebate="trackersubscription.meta_data.rebate"/>
                </div>
                <div class="d-block">
                    {{ trackersubscription | formatSubscriptionYearlyPrice }} 
                    <p class="h6">{{$t('per_year')}}</p>
                </div>
            </div>
        </div>
    </b-button>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RebateBadge from '@/components/ui/RebateBadge';

export default {
    name: 'SubscriptionPriceButton',
    components: {
        RebateBadge,
    },
    props: {
        trackersubscription: {},
    },

    computed: {
        ...mapGetters('subscriptions', ['getSelectedSubscription'])
    },

    methods: {
        ...mapActions('subscriptions', ['setSelectedSubscription'])
    },
}
</script>

<style scoped>
.btn-price {
    border-width: 1.5px;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0.3rem 2rem;
}
.btn-price:hover {
    color: #6c757d;
    border: 1px solid #2097F7;
    background-color: white;
    box-shadow: none;
}

.btn-price:active, .btn-price-active {
    color: #6c757d !important;
    border: 1px solid #2097F7 !important;
    background-color: white !important;
    box-shadow: #007bff !important;
}

.btn-price:focus {
    color: #6c757d;
    border: 1px solid #2097F7;
    background-color: white;
    box-shadow: none !important;
}

.rebate-area {
    min-height: 25px;
}
</style>