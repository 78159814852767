<template>
    <a href="https://www.invoxia.com/uk/legal/store/terms-of-sales" target="_blank" >
        <div class="text-center text-cgu mt-3">{{$t('cgu')}}</div>
    </a>
</template>

<script>
export default {
    name:'CguLink',
}
</script>

<style scoped>
.text-cgu { 
    color: #6c757d8c;
    text-decoration: underline;
    cursor: pointer;
}

.text-cgu:hover {
    text-decoration: underline;
    color: #6c757d;
}
</style>