<template>
    <div class="row h-100 align-items-center">
        <div class="col-12 px-4">
            <div class="text-center my-3 h4 font-weight-bold">
                {{$t('renew_your_subscription')}}
            </div>
            <SubscriptionRemainingDays :tracker="getSelectedDevice" />

            <SubscriptionPriceButton v-for="subscription in getSubscriptionsByDeviceType" :key="subscription.id" :trackersubscription="subscription" />

            <SubscriptionCheckOutButton />

            <CguLink />
        </div>
    </div>
</template>

<script>
import SubscriptionRemainingDays from '@/components/subscription/SubscriptionRemainingDays'
import SubscriptionPriceButton from '@/components/subscription/SubscriptionPriceButton'
import SubscriptionCheckOutButton from '@/components/subscription/SubscriptionCheckOutButton'
import CguLink from '@/components/ui/CguLink'

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: 'SubscriptionSelector',
  components: {
    SubscriptionRemainingDays,
    SubscriptionPriceButton,
    SubscriptionCheckOutButton,
    CguLink
  },
  data() {
    return {
      reason: null,
      stripe: null,
    }
  },

  methods: {
    ...mapActions('subscriptions', ['setSelectedSubscription'])
  },

  computed: {
    ...mapGetters('devices', ['getSelectedDevice']),
    ...mapGetters('subscriptions', ['getSubscriptions']),
    getSubscriptionsByDeviceType() {
      let _type = (this.getSelectedDevice.type == 'tracker_03' ? 'lwt3' : 'lwt1');
      let subscriptionsByDeviceType = [];
      if (_type == 'lwt1' && this.getSelectedDevice.tracker_status.network_operator == 'swisscom') {
        subscriptionsByDeviceType = this.getSubscriptions.filter(subscription => subscription.meta_data.swisscom != undefined);
      } else {
        subscriptionsByDeviceType = this.getSubscriptions.filter(subscription => (subscription.meta_data[_type] != undefined || subscription.meta_data.months == 0) && subscription.meta_data.swisscom == undefined);
      }
      subscriptionsByDeviceType.sort(function(a,b){  
          if(a.meta_data.months > b.meta_data.months)  
            return 1;
          else if(a.meta_data.months < b.meta_data.months)  
            return -1;  
      
          return 0;  
      });
      if (subscriptionsByDeviceType.length){
        this.setSelectedSubscription(subscriptionsByDeviceType[0]);
      }
      return subscriptionsByDeviceType;
    }
  },
}
</script>

<style scoped>
</style>